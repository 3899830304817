.header {
  background: #ffffff1a;
  height: 80px;
  line-height: 80px;
  padding: 0 30px;
}

.header-menu {
  background: none;
  border-bottom: none;
  margin: 20px 0;
}

.header-menu:focus-visible {
  box-shadow: none !important;
}

.header-menu li {
  font-size: 14px;
  font-weight: var(--65medium);
  letter-spacing: 0.02em;
  line-height: 40px;
  color: #fff;
  margin: auto 10px;
}

.header-menu li.ant-menu-item-selected,
.header-menu li.ant-menu-item-active {
  background-color: #0000004d !important;
  border-radius: 4px;
}

.header-menu li.ant-menu-item-selected span,
.header-menu li.ant-menu-item-active span {
  color: #fff;
}

.header-menu li.ant-menu-item-selected:after,
.header-menu li.ant-menu-item-active:after {
  content: none;
}

.header-avatar {
  width: 40px;
  height: 40px;
  border: 4px solid #ffffffa6;
}

.header-avatar:hover {
  cursor: pointer;
}

.header-avatar.ant-dropdown-open {
  border-color: #fff;
  transition: 0.3s ease-in-out;
}

.ant-dropdown-open #bell {
  opacity: 1;
  transition: 0.3s ease-in-out;
}

.ant-dropdown-menu {
  padding: 0px;
  border-radius: 4px;
  overflow: hidden;
}

.user-options {
  min-width: 240px;
}

.notifications-list {
  min-width: 340px;
}

.notifications-list li {
  border-bottom: 1px solid #eceef7;
  padding: 20px;
  position: relative;
}

.notifications-list li > a {
  display: block;
  width: 100%;
}

.notifications-list li > a .ant-list-item-meta-title {
  font-size: 12px;
  letter-spacing: 0.02em;
  line-height: 18px;
  color: #000;
}

.notifications-list li > a .ant-list-item-meta-description {
  font-weight: var(--65medium);
  font-size: 8px;
  letter-spacing: 0.02em;
  /* line-height: 18px; */
  color: #6b6f80;
}

.user-options li a {
  font-weight: var(--65medium);
  font-size: 14px;
  line-height: 49px;
  color: #000;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  border-bottom: 1px solid #eceef7;
  padding: 5px 17px;
  position: relative;
}

.ant-dropdown-menu-item:not(:last-child):hover,
.notifications-list li:hover,
.ant-dropdown-menu-submenu-title:hover,
.notifications-list .ant-dropdown-menu-footer:hover {
  background-color: var(--lightColor);
  border-bottom: 1px solid var(--lightColor);
}

.ant-dropdown-menu-item.dropDownHead:hover {
  background-color: inherit;
  cursor: default;
}

.ant-dropdown-menu-item.dropDownHead:hover:before {
  content: none !important;
}

.ant-dropdown-menu-item:not(:last-child):hover:before,
.notifications-list li:hover:before,
.ant-dropdown-menu-submenu-title:hover:before {
  content: '';
  position: absolute;
  left: 0;
  top: -1px;
  height: calc(100% + 1px);
  width: 3px;
  background-color: var(--purple);
  transition: all 0.3s;
}

.notifications-list .ant-dropdown-menu-header a {
  font-weight: var(--65medium);
}

.notifications-list .ant-dropdown-menu-header a:hover {
  color: var(--purple);
}

.dropDownHead {
  padding: 23px 17px 17px;
}

.ant-dropdown-menu-header {
  border-bottom: 1px solid var(--lightColor);
  padding: 23px 17px 17px;
}

.name {
  font-weight: var(--65medium);
  font-size: 14px;
  line-height: 21px;
  color: var(--purple);
}

.email {
  font-weight: var(--55roman);
  font-size: 12px;
  letter-spacing: 0.02em;
  line-height: 20px;
  color: var(--lightGray);
}

.dropDownLogout {
  border-bottom: none;
}

.dropDownLogout:hover,
.notifications-list .ant-dropdown-menu-footer:hover {
  background-color: #fff;
}

.dropDownLogout:hover a.logout,
.notifications-list .ant-dropdown-menu-footer:hover a {
  color: #000;
}

.ant-dropdown-menu-title-content > a.logout,
.notifications-list .ant-dropdown-menu-footer a {
  font-weight: var(--65medium);
  font-size: 12px;
  letter-spacing: 0.02em;
  line-height: 48px;
  color: var(--lightGray);
}

.notifications-list .ant-dropdown-menu-footer a {
  display: block;
  width: 100%;
}

.notifications-list .ant-dropdown-menu-footer {
  padding: 5px 17px;
  position: relative;
}

.ant-dropdown-menu-header h4 {
  font-weight: var(--65medium);
  font-size: 14px;
  line-height: 40px;
  color: var(--purple);
  margin-bottom: 0px;
}

.nav-dd {
  list-style: none;
}

.nav-dd li {
  display: inline-block;
}

.nav-dd li:not(:first-child) {
  margin-left: 24px;
}

/* .notification-bell {
position: relative;
top: 8px;
}

.notifications sup {
width: 8px;
height: 8px;
display: block;
border-radius: 50%;
background-color: #4ee330;
position: absolute;
top: -2px;
right: -4px;
}*/

.notifications {
  position: relative;
  top: 8px;
}

.notifications:hover {
  cursor: pointer;
}

.notifications-list ul {
  padding: 0;
  margin: 0;
}

.logo img {
  opacity: 0.8;
}
/* .logo:hover img {
  opacity: 1;
} */

.alert-wrapper {
  position: absolute;
  top: 35px;
  left: 15px;
  max-width: calc(100% - 30px);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f9e3e3;
  border-radius: 4px;
  padding: 0 10px 0 24px;
}

.pf-alert-text {
  font-weight: var(--65medium);
  font-size: 14px;
  letter-spacing: 0.02em;
  line-height: 40px;
  color: #d34242;
}

@media (max-width: 767px) {
  .alert-wrapper {
    position: static;
    flex-direction: column;
    max-width: 100%;
    margin: 20px 0 0;
    padding: 0px 0 15px;
  }
}
