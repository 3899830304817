.sidebar-card {
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.16);
  padding: 47px 0 0;
  overflow: hidden;
  margin-bottom: 40px;
}

.sidebar-card-1 {
  padding: 47px 0 0;
}

.sidebar-card-2 {
  padding: 28px 0 10px;
}

.sidebar-card-3 {
  padding: 28px 0 0;
}

.avatar-sidebar {
  margin-bottom: 15px;
}

.name-sidebar {
  font-weight: var(--65medium);
  font-size: 20px;
  letter-spacing: 0.02em;
  color: var(--purple);
  margin-bottom: 0;
}

.location-sidebar {
  font-weight: var(--65medium);
  font-size: 14px;
  letter-spacing: 0.02em;
  color: var(--lightGray);
}

.user-details-sidebar {
  padding-bottom: 22px;
  border-bottom: 1px solid #f0f0f0;
}

.ant-progress-success-bg,
.ant-progress-bg {
  background: var(--gradient);
  height: 15px !important;
}

.ant-progress-inner {
  background-color: #dcdee6;
}

.profile-completion {
  padding: 19px 30px 26px;
  border-bottom: 1px solid #f0f0f0;
}

.profile-completion h3 {
  font-weight: var(--65medium);
  font-size: 12px;
  letter-spacing: 0.02em;
  color: #000;
  margin-bottom: 2px;
}

.profile-completion h3.completion-percentage {
  text-align: right;
  color: var(--purple);
}

.nav-sidebar-desktop {
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-sidebar-desktop li:not(:last-child) > a {
  font-weight: var(--65medium);
  font-size: 14px;
  letter-spacing: 0.02em;
  line-height: 59px;
  color: #000;
  display: block;
  padding: 0 27px;
  border-bottom: 1px solid #f0f0f0;
  transition: all 0.3s;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5.245' height='8.128' viewBox='0 0 5.245 8.128'%3E%3Cpath id='chevron-right' d='M-.8-4.443a.608.608,0,0,0,0-.863L-4.261-8.76a.607.607,0,0,0-.861,0l-.571.579a.607.607,0,0,0,0,.861l2.448,2.448L-5.693-2.425a.607.607,0,0,0,0,.861l.574.574a.607.607,0,0,0,.861,0Z' transform='translate(5.872 8.939)' fill='%234f46e4' opacity='0.65'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: right 30px center;
  background-size: 5.25px;
}

.nav-sidebar-desktop li:not(:last-child) > a:hover {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5.245' height='8.128' viewBox='0 0 5.245 8.128'%3E%3Cpath id='chevron-right' d='M-.8-4.443a.608.608,0,0,0,0-.863L-4.261-8.76a.607.607,0,0,0-.861,0l-.571.579a.607.607,0,0,0,0,.861l2.448,2.448L-5.693-2.425a.607.607,0,0,0,0,.861l.574.574a.607.607,0,0,0,.861,0Z' transform='translate(5.872 8.939)' fill='%234f46e4' opacity='1'/%3E%3C/svg%3E%0A");
}

.nav-sidebar-desktop li:last-child a {
  font-weight: var(--65medium);
  font-size: 12px;
  letter-spacing: 0.02em;
  line-height: 48px;
  color: #6b6f80;
  display: block;
  padding: 0 27px;
  border-bottom: none;
  transition: all 0.3s;
}

.nav-sidebar-desktop li > a {
  position: relative;
}

.nav-sidebar-desktop li:not(:last-child) a:hover,
.nav-sidebar-desktop li:not(:last-child) a.active {
  background-color: var(--lightColor);
  transition: all 0.3s;
}

.nav-sidebar-desktop li:last-child a:hover {
  color: #000;
}

.nav-sidebar-desktop li:not(:last-child) a:hover,
.nav-sidebar-desktop li:not(:last-child) a.active {
  border-bottom: 1px solid var(--lightColor);
}

.nav-sidebar-desktop li:last-child a:hover,
.nav-sidebar-desktop li:last-child a.active {
  border-bottom: none;
}

.nav-sidebar-desktop li:not(:last-child) a:hover:before,
.nav-sidebar-desktop li:not(:last-child) a.active:before {
  content: '';
  position: absolute;
  left: 0;
  top: -1px;
  height: calc(100% + 1px);
  width: 3px;
  background-color: var(--purple);
  transition: all 0.3s;
}

.sidebar-avatar-holder {
  border: 3px solid var(--purple);
  border-radius: 50%;
  position: relative;
  margin-bottom: 14px;
}

.sidebar-avatar-holder:hover {
  border-color: #000;
}

.sidebar-avatar-holder:hover .icon-camera {
  background-color: #000;
}

.icon-camera {
  position: absolute;
  width: 28px;
  height: 28px;
  background-color: var(--purple);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='9.625' viewBox='0 0 11 9.625'%3E%3Cpath id='icon' d='M0-6.531A1.032,1.032,0,0,0-1.031-7.562H-2.922l-.266-.707a1.03,1.03,0,0,0-.965-.668h-2.7a1.03,1.03,0,0,0-.965.668l-.264.707H-9.969A1.032,1.032,0,0,0-11-6.531V-.344A1.032,1.032,0,0,0-9.969.688h8.938A1.032,1.032,0,0,0,0-.344ZM-2.922-3.437A2.58,2.58,0,0,1-5.5-.859,2.58,2.58,0,0,1-8.078-3.437,2.58,2.58,0,0,1-5.5-6.016,2.58,2.58,0,0,1-2.922-3.437Zm-.687,0A1.893,1.893,0,0,0-5.5-5.328,1.893,1.893,0,0,0-7.391-3.437,1.893,1.893,0,0,0-5.5-1.547,1.893,1.893,0,0,0-3.609-3.437Z' transform='translate(11 8.938)' fill='%23fff'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 12px;
  border-radius: 50%;
  border: 3px solid #fff;
  right: 4px;
  bottom: 10px;
}

.sidebar-avatar-2 {
  border: 3px solid var(--purple);
  margin-bottom: 14px;
}
.ant-progress-steps-outer {
  background: var(--gradient);
  /* border-radius: 08px; */
}
.ant-progress-steps-outer:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.ant-progress-steps-item:not(:last-child) {
  border-right: 2px solid #fff;
}
.ant-progress-steps-outer:last-child,
.ant-progress-steps-item:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.ant-progress-steps-item {
  width: 76px !important;
  background: #e7e3e3;
  margin: 0px;
}

.ant-progress-steps-item-active {
  background: transparent;
}

@media (max-width: 991px) {
  .sidebar-card-1 {
    display: none;
  }
}

.single-campaigns
  .sb-desktop
  .ant-progress-steps-item:first-child:not(.ant-progress-steps-item-active) {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
